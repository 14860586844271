/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
export const momentLocaleImports = {
    bg: () => import('moment/dist/locale/bg'),
    cs: () => import('moment/dist/locale/cs'),
    da: () => import('moment/dist/locale/da'),
    de: () => import('moment/dist/locale/de'),
    en: () => import('moment/dist/locale/en-gb'),
    es: () => import('moment/dist/locale/es'),
    et: () => import('moment/dist/locale/et'),
    fi: () => import('moment/dist/locale/fi'),
    fr: () => import('moment/dist/locale/fr'),
    hr: () => import('moment/dist/locale/hr'),
    hu: () => import('moment/dist/locale/hu'),
    it: () => import('moment/dist/locale/it'),
    lt: () => import('moment/dist/locale/lt'),
    lv: () => import('moment/dist/locale/lv'),
    nb: () => import('moment/dist/locale/nb'),
    nl: () => import('moment/dist/locale/nl'),
    pl: () => import('moment/dist/locale/pl'),
    pt: () => import('moment/dist/locale/pt'),
    'pt-br': () => import('moment/dist/locale/pt-br'),
    ro: () => import('moment/dist/locale/ro'),
    sk: () => import('moment/dist/locale/sk'),
    sl: () => import('moment/dist/locale/sl'),
    sv: () => import('moment/dist/locale/sv'),
};
