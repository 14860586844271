import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storage } from '../../../features/browsercommunication/localStorage/storage';

export enum LocalStorageKey {
    HISTORIC_QUERY_WIDGET_OPTIONS = 'HISTORIC_QUERY_WIDGET_OPTIONS',
    HISTORIC_QUERY_SPA_OPTIONS = 'HISTORIC_QUERY_SPA_OPTIONS',
}

const saveOnboardingTip = (localStorageKey: LocalStorageKey, value: boolean): void => {
    storage.saveDomainObject(localStorageKey, value);
};
const loadOnboardingTip = (localStorageKey: LocalStorageKey): boolean => {
    const loadedShowTipFlag = storage.loadDomainObject(localStorageKey);
    return loadedShowTipFlag === undefined ? true : loadedShowTipFlag;
};

const initialState = {
    [LocalStorageKey.HISTORIC_QUERY_WIDGET_OPTIONS]: loadOnboardingTip(LocalStorageKey.HISTORIC_QUERY_WIDGET_OPTIONS),
    [LocalStorageKey.HISTORIC_QUERY_SPA_OPTIONS]: loadOnboardingTip(LocalStorageKey.HISTORIC_QUERY_SPA_OPTIONS),
};

interface OnboardingState {
    [key: string]: boolean;
}

const { reducer, actions } = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setShowOnboardingTip: (
            state: OnboardingState,
            action: PayloadAction<{ type: LocalStorageKey; value: boolean }>
        ): void => {
            state[action.payload.type] = action.payload.value;
            saveOnboardingTip(action.payload.type, action.payload.value);
        },
    },
});

export const onboardingActions = actions;
export const onboardingReducer = reducer;
