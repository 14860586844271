import getOr from 'lodash/fp/getOr';

import { changeLocale, languageDataFetched } from './actions';
import { getSupportedLocale as defaultGetSupportedLocale } from './selectors';
import { trace } from '../setup/trace';
import { sendErrorToSentry } from '../setup/sentry';
import { momentLocaleImports } from './momentLocales'; // Webpack is weird sometimes, maybe it's Babel, who knows...

// Webpack is weird sometimes, maybe it's Babel, who knows...
const normalizeDynamicImport = (imported) => getOr(imported, 'default', imported);

const importMomentLocales = async (country) => {
    if (country in momentLocaleImports) {
        await momentLocaleImports[country]();
    }
};

export const getLanguageData = (locale) =>
    import(/* webpackChunkName: "[request]" */ `../../translations/${locale}.json`)
        .then(normalizeDynamicImport)
        .catch((error) => {
            console.error(error);
            sendErrorToSentry(error);
            return error;
        });

export const configureFetchLanguageData =
    (store, fetchLanguageData = getLanguageData, getSupportedLocale = defaultGetSupportedLocale) =>
    (locale) => {
        if (!locale) {
            console.warn('No "locale" supplied when fetching language data!');
            return Promise.reject();
        }

        store.dispatch(changeLocale(locale));
        const supportedLocale = getSupportedLocale(store.getState());

        const country = locale.substring(0, 2);

        const momentPromise = importMomentLocales(country)
            .then(() => {
                trace(`imported momentLocale of country: ${country}`);
            })
            .catch((error) => {
                console.error(`momentLocale of country ${country} could not be imported`, error);
                sendErrorToSentry(error);
            });

        const fetchLanguageDatePromise = fetchLanguageData(supportedLocale)
            .then((languageData) => {
                trace(`Language data fetched for "${supportedLocale}"`);
                store.dispatch(languageDataFetched(supportedLocale, languageData));
            })
            .catch((error) => {
                console.error(`Language data for "${supportedLocale}" could not be fetched.`, error);
                sendErrorToSentry(error);
                return error;
            });

        return Promise.all([momentPromise, fetchLanguageDatePromise]);
    };
