import { HistoricEventType } from '../../../domain/model/historicEvent.types';

export enum TableColumn {
    DETAILS = 'details',
    TIMESTAMP = 'timestamp',
    DRIVER_NAME = 'driverName',
    MILEAGE = 'mileage',
    SPEED = 'speed',
    FUEL_LEVEL = 'fuelLevel',
    ADDRESS = 'address',
    DISTANCE_TO_PREVIOUS = 'distanceToPrevious',
    DURATION_SINCE_PREVIOUS = 'durationSincePrevious',
    STATE_OF_CHARGE = 'stateOfCharge',
}

const columnLabels: { [key in TableColumn]: string } = {
    [TableColumn.DETAILS]: 'fleetmonitor.eventHistory.eventDetails',
    [TableColumn.TIMESTAMP]: 'fleetmonitor.eventHistory.event.prop.timestamp',
    [TableColumn.DRIVER_NAME]: 'fleetmonitor.eventHistory.event.prop.driverName',
    [TableColumn.MILEAGE]: 'fleetmonitor.eventHistory.event.prop.mileage',
    [TableColumn.ADDRESS]: 'fleetmonitor.eventHistory.event.prop.address',
    [TableColumn.DISTANCE_TO_PREVIOUS]: 'fleetmonitor.eventHistory.event.prop.distanceToPrevious',
    [TableColumn.DURATION_SINCE_PREVIOUS]: 'fleetmonitor.eventHistory.event.prop.durationSincePrevious',
    [TableColumn.SPEED]: 'fleetmonitor.eventHistory.event.prop.speed',
    [TableColumn.FUEL_LEVEL]: 'fleetmonitor.eventHistory.event.prop.fuelLevel',
    [TableColumn.STATE_OF_CHARGE]: 'fleetmonitor.eventHistory.event.prop.stateOfCharge',
};

const defaultColumnOrder: TableColumn[] = [
    TableColumn.DETAILS,
    TableColumn.TIMESTAMP,
    TableColumn.DRIVER_NAME,
    TableColumn.MILEAGE,
    TableColumn.SPEED,
    TableColumn.FUEL_LEVEL,
    TableColumn.STATE_OF_CHARGE,
    TableColumn.ADDRESS,
    TableColumn.DISTANCE_TO_PREVIOUS,
    TableColumn.DURATION_SINCE_PREVIOUS,
];

const disabledColumns = [TableColumn.TIMESTAMP, TableColumn.DETAILS];

const defaultHiddenColumns: TableColumn[] = [];

export const eventTableDefinition = {
    defaultColumnOrder,
    disabledColumns,
    defaultHiddenColumns,
    columnLabels,
};

export interface HistoryTableColumns {
    columnOrder: TableColumn[];
    hiddenColumns: TableColumn[];
}

export interface ColumnSettings {
    columnOrder: TableColumn[];
    hiddenColumns: TableColumn[];
}

export interface FormattedEventDetails {
    name: string;
    description?: string;
}

export interface FormattedHistoricEvent {
    id: string;
    type: HistoricEventType;
    details: FormattedEventDetails;
    columns: { [key in Exclude<TableColumn, TableColumn.DETAILS>]?: string | undefined };
}
